/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import each from "lodash/each";
import isEmpty from "lodash/isEmpty";

export default class ExcelPrinter {
    /**
     * Create string to use as title
     * @return string
     * */
    getTitle() {
        return 'ExcelPrinter';
    }


    /**
     * Populate options to be passed in {workbook.addWorksheet()} method as second argument
     * @return object
     * */
    getWorksheetOptions() {
        return {};
    }


    getCreator() {
        return process.env.VUE_APP_TITLE;
    }


    /**
     * Return data to be handled
     * */
    getData() {
        return {};
    }


    /**
     * Create excel object
     * */
    createWorkBook() {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(this.getTitle(), this.getWorksheetOptions());

        workbook.creator = this.getCreator();

        // apply columns
        const columns = this.getColumns();
        if (columns.length > 0) {
            worksheet.columns = columns;
            worksheet.getRow(1).font = {bold: true};
        }
        each(this.getData(), (dataRow, dataKey) => this.applyDataRow(worksheet, dataRow, dataKey));

        const totals = this.getTotals();
        if (!isEmpty(totals)) {
            this.applyTotalRow(worksheet, totals);
        }
        return workbook;
    }


    /**
     * Define columns
     * */
    getColumns() {
        return [];
    }


    /**
     * Handle rows by order data
     * */
    applyDataRow(worksheet, dataRow, dataKey) {
        worksheet.addRow(dataRow);
    }


    /**
     * The totals data to be used at footer
     * @return array|null
     * */
    getTotals() {
        return null;
    }


    /**
     * Handle footer row with totals
     * */
    applyTotalRow(worksheet, data) {
        worksheet.addRow(data);
    }


    /**
     * Do "save as" prompt
     * */
    async writeFile(fileName) {
        let workbook = this.createWorkBook();
        let buffer;
        try {
            buffer = await workbook.xlsx.writeBuffer(fileName);
            let file = new File([buffer], fileName, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            FileSaver.saveAs(file);
        } catch (e) {
            console.error(e);
        }
        return buffer;
    }
}